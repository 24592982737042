// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: 100%
}
@media (min-width: 640px) {
    .container {
        max-width: 640px
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 768px
    }
}
@media (min-width: 1024px) {
    .container {
        max-width: 1024px
    }
}
@media (min-width: 1280px) {
    .container {
        max-width: 1280px
    }
}
@media (min-width: 1536px) {
    .container {
        max-width: 1536px
    }
}
.visible {
    visibility: visible
}
.static {
    position: static
}
.sticky {
    position: sticky
}
.float-right {
    float: right
}
.my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem
}
.my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem
}
.my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem
}
.\\!ml-10 {
    margin-left: 2.5rem !important
}
.mb-12 {
    margin-bottom: 3rem
}
.ml-4 {
    margin-left: 1rem
}
.mr-3 {
    margin-right: 0.75rem
}
.mt-12 {
    margin-top: 3rem
}
.mt-2 {
    margin-top: 0.5rem
}
.block {
    display: block
}
.flex {
    display: flex
}
.table {
    display: table
}
.contents {
    display: contents
}
.hidden {
    display: none
}
.\\!w-\\[10\\%\\] {
    width: 10% !important
}
.\\!w-\\[15\\%\\] {
    width: 15% !important
}
.\\!w-\\[20\\%\\] {
    width: 20% !important
}
.\\!w-\\[25\\%\\] {
    width: 25% !important
}
.\\!w-\\[50\\%\\] {
    width: 50% !important
}
.max-w-\\[65em\\] {
    max-width: 65em
}
.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.resize {
    resize: both
}
.gap-4 {
    gap: 1rem
}
.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem
}
.text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem
}
.font-bold {
    font-weight: 700
}
.uppercase {
    text-transform: uppercase
}
.italic {
    font-style: italic
}
.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.underline {
    text-decoration-line: underline
}
.shadow {
    --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.blur {
    --tw-blur: blur(8px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.filter {
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms
}`, "",{"version":3,"sources":["webpack://./src/styles/tailwind.css"],"names":[],"mappings":"AAAA;IAAA;AAAoB;AAApB;IAAA;QAAA;IAAoB;AAAA;AAApB;IAAA;QAAA;IAAoB;AAAA;AAApB;IAAA;QAAA;IAAoB;AAAA;AAApB;IAAA;QAAA;IAAoB;AAAA;AAApB;IAAA;QAAA;IAAoB;AAAA;AACpB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,mBAAmB;IAAnB;AAAmB;AAAnB;IAAA,gBAAmB;IAAnB;AAAmB;AAAnB;IAAA,mBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,mBAAmB;IAAnB;AAAmB;AAAnB;IAAA,kBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,oBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,0EAAmB;IAAnB,8FAAmB;IAAnB;AAAmB;AAAnB;IAAA,oBAAmB;IAAnB;AAAmB;AAAnB;IAAA;AAAmB;AAAnB;IAAA,gKAAmB;IAAnB,wJAAmB;IAAnB,iLAAmB;IAAnB,wDAAmB;IAAnB;AAAmB","sourcesContent":["@tailwind components;\n@tailwind utilities;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
